import React from 'react';

function CV() {
  return (
    <div className="flex flex-col ">
      <div className="flex flex-col items-center space-y-4">
        <p className="text-blue-500 hover:text-blue-600 font-bold">
          <a href="/Numan Dirican CV.pdf" target="_blank">View CV</a>
        </p>
		<p className="text-gray-300">You can view my CV by clicking the link above.</p>
      </div>
    </div>
  );
}

export default CV;
