import { GoVerified } from "react-icons/go";
import { SiGithub, SiGmail, SiLinkedin } from "react-icons/si";

export default function Bio() {
	const links = [
		{
			title: "Github",
			Icon: SiGithub,
			href: "https://github.com/ruskyvisky",
			text: "@Ruskyvisky",
		},
		{
			title:"Linkedin",
			Icon: SiLinkedin,
			href:"https://www.linkedin.com/in/numan-dirican/",
			text:"Numan Dirican"
		},
		{
			title:"Mail",
			Icon: SiGmail,
			href:"mailto:",
			text: "diricannuman@gmail.com"
		}
		

	];
	return (
		<div className="mt-2 mb-2 w-11/12 mx-auto">
			<div className="flex items-center gap-3 mb-3 ">
				<img
					src="https://avatars.githubusercontent.com/u/46764372?s=400&u=ae4025b0161abf3f75e1beb2cfc8737b8548d744&v=4"
					alt="Ruskyvisky"
					className="w-40 h-40 rounded-full border-2 border-yellow-200"
				/>
				<div>
					<div className="flex items-center gap-1">
						<h1 className="text-2xl">Ruskyvisky</h1>
						<GoVerified className="text-blue-500 text-2xl" />
					</div>
					<p className="text-lg text-gray-400">Join the world 22 years ago.</p>
				</div>
			</div>
			<p>
				Hi there, I am a software developer/student. I am currently studying
				Management Information Systems at Trakya University. I am interested in
				developing web applications and mobile applications. I am trying to improve
				myself in these fields. I am trying to learn new things every day. I
				share my projects on Github. 
			</p>
			<div className="grid grid-cols-2">
				{links.map(({ Icon, text, href, title }, index) => {
					return (
						<div className="flex items-center gap-1 mt-3" key={index}>
							<div className="text-lg flex items-center gap-1 text-yellow-200">
								<Icon />
								<h1>{title}</h1>
							</div>
							<a
								href={href}
								target="_blank"
								rel="noreferrer"
								className="flex-1 underline text-blue-300 "
							>
								{text}
							</a>
						</div>
					);
				})}
			</div>
		</div>
	);
}
