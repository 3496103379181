import { useState, useEffect } from "react";
import {FaJava} from "react-icons/fa";
import {
	SiNextdotjs,
	SiReact,
	SiTailwindcss,
	SiSpringboot,
	SiDocker,
	SiGit,
	SiGithub,
	SiHtml5,
	SiCss3,
	SiMysql,
	SiTypescript,
	SiMongodb,
	SiJavascript,
	SiPostman,
} from "react-icons/si";

export default function Skills() {
	const techs = [
		{ Icon: SiReact, color: "text-blue-500", duration: "duration-100", name: "React" },
		{ Icon: SiJavascript, color: "text-yellow-400", duration: "duration-200", name: "Javascript" },
		{ Icon: SiSpringboot , color: "text-green-400", duration: "duration-300" , name: "Spring Boot" },
		{ Icon: SiNextdotjs, color: "text-black-500", duration: "duration-400", name: "Next.js" },
		{ Icon: SiTypescript, color: "text-blue-500", duration: "duration-500", name: "Typescript" },
		{ Icon: SiTailwindcss, color: "text-blue-300", duration: "duration-600" , name: "Tailwind" },
		{ Icon: SiDocker, color: "text-blue-500", duration: "duration-700", name: "Docker" },
		{ Icon: SiGit, color: "text-orange-600", duration: "duration-800", name: "Git" },
		{ Icon: SiGithub, color: "text-white", duration: "duration-900", name: "Github" },
		{ Icon: FaJava, color: "text-blue-500", duration: "duration-1000", name: "Java" },
		{ Icon: SiHtml5, color: "text-orange-600", duration: "duration-1000", name: "Html" },
		{ Icon: SiCss3, color: "text-blue-500", duration: "duration-1000", name: "Css" },
		{ Icon: SiMysql, color: "text-blue-500", duration: "duration-1000", name: "Mysql" },
		{ Icon: SiMongodb, color: "text-green-400", duration: "duration-1000", name: "MongoDB" },
		{ Icon: SiPostman, color: "text-orange-600", duration: "duration-1000", name: "Postman" },

	];
	const [animate, setAnimate] = useState(false);

	useEffect(() => {
		setAnimate(true);
	}, []);
	return (
		<div className="flex flex-wrap gap-5 text-7xl">
    {techs.map(({ Icon, color, duration, name }, index) => {
        return (
            <div key={index} className="flex items-center">
                <Icon
                    className={`${color} ${
                        animate ? "scale-100" : "scale-0"
                    } transform transition-all ${duration}`}
                />
                <p className="text-sm p-2 font-bold">{name}</p>
            </div>
        );
    })}
</div>
	);
}
