import { SiGithub } from 'react-icons/si';

const projects = [
    {
        name: "Netflix Clone",
        githubLinks: ["https://github.com/ruskyvisky/netflix-app"],
        technologies: ["Next.js", "Tailwind CSS", "MongoDB", "Prisma.js"],
    },
    {
        name: "Random Coffee App",
        githubLinks: ["https://github.com/ruskyvisky/Random-Coffee-App"],
        technologies: ["Spring Boot","MySQL"]
    },
    {
        name: "Event Organizator App",
        githubLinks: ["https://github.com/ruskyvisky/Event-Organizator-Frontend", "https://github.com/ruskyvisky/Event-Organizator-backend"],
        technologies: ["Spring Boot", "Spring Security", "Next.js" ,"MySQL", "Chakra UI"]
    },
];

const ProjectsList = () => {
    return (
        <>
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
            {projects.map((project, index) => (
                <div key={index} className="bg-gray-100 rounded-lg p-6 shadow-md">
                    <div className="mb-2">
                        <a href={project.githubLinks[0]} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">{project.name}</a>
                    </div>
                    {project.githubLinks.length > 1 && (
                        <div className="mb-2">
                            <ul>
                                {project.githubLinks.slice(1).map((link, index) => (
                                    <li key={index}><a href={link} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">Backend</a></li>
                                ))}
                            </ul>
                        </div>
                    )}
                    <p className="text-gray-700 mb-2">Technologies Used:</p>
                    <p className="text-gray-700 mb-4">
                        {project.technologies.join(", ")}
                    </p>
                </div>
            ))}
            

            
        </div>
<p className='mt-3'>For more projects, please visit my <a href="https://github.com/ruskyvisky" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">GitHub</a> profile.</p>

        </>
    );
};

export default ProjectsList;

// 